<template>
  <div style="padding-bottom:44px">
    <van-field left-icon="scan" label="序列号" v-model="czy005_Step2.serialNumber" placeholder="点左图扫设备屏幕上的二维码" @click-left-icon="handleRegisterByScan">
      <template #right-icon>
        <van-tag type="warning">{{jieMa}}</van-tag>
      </template>
    </van-field>
    <van-cell title="温度通道1 2测试">
      <template #right-icon>
        <van-checkbox v-model="czy005_Step2.wenDuTongDaoCeShi12" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="计数测试">
      <template #right-icon>
        <van-checkbox v-model="czy005_Step2.jiShuCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="加热1 加热2 测试">
      <template #right-icon>
        <van-checkbox v-model="czy005_Step2.jiaReCeShi12" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="电机 风机 照明测试">
      <template #right-icon>
        <van-checkbox v-model="czy005_Step2.dianJiFengJiZhaoMingCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="升级测试">
      <template #right-icon>
        <van-checkbox v-model="czy005_Step2.shengJiCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="打印机测试">
      <template #right-icon>
        <van-checkbox v-model="czy005_Step2.daYinJiCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-field label="备注" v-model="czy005_Step2.remark"> </van-field>
    <el-button type="primary" class="footer1" icon="el-icon-view" circle @click="handlePicClick"></el-button>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step2" round block :disabled="$parent.czy005_SignNumber.status!=0" @click="handleSave">保存</van-button>
      <van-button type="danger" v-if="$parent.czy005_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.czy005_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>

    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  props: ["signNumber"],
  data() {
    return {
      czy005_Step2: {},
      jieMa: "",
    };
  },
  methods: {
    handlePicClick() {
      ImagePreview(["./images/CZY005_Produce.png"]);
    },
    getCZY005_Step2() {
      let that = this;
      that.axios
        .post("/CZY005/GetCZY005_Step2", { onlyText: that.signNumber })
        .then(function (response) {
          that.czy005_Step2 = response.data.data;
        });
    },
    handleSave() {
      let that = this;
      that.axios
        .all([that.save_CZY005_Step2(), that.registerSerialNumber()])
        .then(
          that.axios.spread(function (response1, response2) {
            that.czy005_Step2 = response1.data.data;
            if (response1.data.code == 201) {
              that.$notify({ type: "warning", message: response1.data.msg });
            } else if (response2.data.code == 201) {
              that.$notify({ type: "warning", message: response2.data.msg });
            } else {
              that.$notify({ type: "success", message: response1.data.msg });
            }
            if (response2.data.code == 101) {
              that.jieMa = response2.data.msg;
            }
          })
        );
    },
    save_CZY005_Step2() {
      let that = this;
      return that.axios.post("CZY005/UpdateCZY005_Step2", that.czy005_Step2);
    },
    registerSerialNumber() {
      let that = this;
      return that.axios.post("Base_Device/Register", {
        productName: "车辙仪(CZY005)",
        productId: 1128,
        serialNumber: that.czy005_Step2.serialNumber,
        deviceType: "CZY005",
        companyName: "",
        ifPrint: false,
      });
    },
    handleRegisterByScan() {
      let that = this;
      that.wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"],
        success: function (res) {
          let url = new URL(res.resultStr);
          that.czy005_Step2.serialNumber = url.searchParams.get("s");
          //let deviceType = url.searchParams.get("n");
          //这里暂时只把序列号读出来
          //   that.axios
          //     .post("Base_Device/Register", {
          //       serialNumber: serialNumber,
          //       deviceType: deviceType,
          //       ifPrint: false,
          //     })
          //     .then(function (response) {
          //       console.log(response);
          //     });
        },
      });
    },
  },
  mounted() {
    let that = this;
    that.getCZY005_Step2();
    //获取公众号扫码的凭证
    that.axios
      .post("OfficialAccount/GetWXScanAPI", { onlyText: window.location.href })
      .then(function (response) {
        that.wx = require("weixin-js-sdk");
        that.wx.config({
          debug: false,
          appId: response.data.data.appId,
          timestamp: response.data.data.timestamp,
          nonceStr: response.data.data.nonceStr,
          signature: response.data.data.signature,
          jsApiList: ["scanQRCode"],
        });
        that.wx.ready(function () {});
        that.wx.error(function (err) {
          alert("error" + JSON.stringify(err));
        });
      });
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
.footer1 {
  position: fixed;
  left: 10px;
  bottom: 50px;
}
</style>